import React, { Component } from 'react'
import Header from '../components/Header';
import SheetkraftFooter from '../components/SheetkraftFooter.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import styles from './careers.module.css';
import logo from '../../src/images/Logo.png'
import Button from 'react-bootstrap/Button'
import Card from "react-bootstrap/Card";
import Accordion from 'react-bootstrap/Accordion'
import '../styles/main.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBriefcase, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import pic1 from '../images/Picture1.jpg'
import pic2 from '../images/Picture2.jpg'
import pic3 from '../images/Picture3.jpg'
import Fade from 'react-reveal/Slide';

export default class Careers extends Component {
    constructor(props) {
        super(props)

        this.state = {
            plus1: false,
            plus2: false,
            plus3: false,
            plus4: false,
            plus5: false

        }
    }


    handleClick = (eventKey) => {
        switch (eventKey) {
            case 0: {
                this.setState({ plus1: !this.state.plus1, plus2: false, plus3: false, plus4: false, plus4: false, plus5: false });
                break;
            }

            case 1: {
                this.setState({ plus2: !this.state.plus2, plus1: false, plus3: false, plus4: false, plus4: false, plus5: false });
                break;
            }

            case 2: {
                this.setState({ plus3: !this.state.plus3, plus1: false, plus2: false, plus4: false, plus5: false });
                break;
            }
            case 3: {
                this.setState({ plus4: !this.state.plus4, plus1: false, plus2: false, plus3: false, plus5: false });
                break;
            }
            case 4: {
                this.setState({ plus5: !this.state.plus5, plus1: false, plus2: false, plus3: false, plus4: false });
                break;
            }


            default:
                break;
        }

    };

    render() {
        const plusIcon = <i className="fa fa-plus"></i>;
        const minusIcon = <i className="fa fa-minus"></i>;
        const unread1 = this.state.plus1;
        const unread2 = this.state.plus2;
        const unread3 = this.state.plus3;
        const unread4 = this.state.plus4;
        const unread5 = this.state.plus5;
        return (
            <div>
                < Helmet>

                    <meta name="description" property="og:description"
                        content="SheetKraft builds high quality web-based applications using pre-designed powerful building blocks and connects to diverse sources of data across all your internal business applications" />
                    <meta name="title" property="og:title" content="SheetKraft" />
                    <meta name="image" property="og:image" content={logo} />
                    <meta name="author" content="SheetKraft" />
                    <meta name="keywords"
                        content=" automation, automation anywhere, uipath, rpa, robotic process automation, automate processes, robotic automation process, rpa services, rpa software, rpa tools, blue prism,no code, rapid application development,business process automation " />

                    <title>SheetKraft - Careers</title>

                </Helmet>
                < Header />

                <div className={`container ${styles.container}`}>
                    <Fade bottom>
                        <div className="row">
                            <div className={`col-lg-12 ${styles.flex}`}>
                                <h1 className={` ${styles.blueColor}`}><strong>Join Our Team</strong> </h1>
                                <p className="lead">We are always searching for amazing people to join our team.</p>
                                <div className={`row ${styles.marginTop}`}>
                                    <div className="col-lg-6">
                                        <img src={pic1} alt="Does Startup Culture Excite You In Particular?" className="img-thumbnail img-fluid" />
                                    </div>
                                    < div className={`col-lg-6 ${styles.textLeft}`} >
                                        <h4 className={styles.blueColor}>Does Startup Culture Excite You In Particular?</h4>
                                        <p>Quantum Phinance was incubated at IIT in 2006, we are a young company which believes in brainstorming and welcome ideas from all our team members even a newbie, so we hold the enthusiasm and we love our time at work because of the friendly culture.</p>
                                    </div>
                                </div>
                                <div className={`row ${styles.marginTop}`}>
                                    <div className={`col-lg-6 ${styles.textLeft}`} id="second">
                                        <h4 className={styles.blueColor}>Does Technology Bring Butterflies In Your Stomach?</h4>
                                        <p>Since its inception, Quantum Phinance has created 3 IT Products so far which we create for BFSI sector. At work we eat, breathe and sleep over tech talks. If you can relate to this, Quantum Phinance is the place for you.</p>
                                    </div>
                                    <div className="col-lg-6" id="first">
                                        <img src={pic2} alt="Does Technology Bring Butterflies In Your Stomach?" className="img-thumbnail img-fluid" />
                                    </div>
                                </div>
                                <div className={`row ${styles.marginTop}`}>
                                    <div className="col-lg-6">
                                        <img src={pic3} alt="All Work And No Play Makes Jack A Dull Boy?" className="img-thumbnail img-fluid" />
                                    </div>
                                    <div className={`col-lg-6 ${styles.textLeft}`}>
                                        <h4 className={styles.blueColor}>All Work And No Play Makes Jack A Dull Boy?</h4>
                                        <p>Quantum Phinance believes in working and partying hand in hand, we have our team dinner’s and Yearly Team Outings. We love to celebrate all the festivals with same zeal! You take care of the work and we will take care of you!</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Fade>
                    <div className="row">
                        <div className={`col-lg-12 ${styles.flex}`}>
                            <div>
                                <h2 className="display-5">Interested? We're Hiring</h2>
                                <p className="lead">Take a look at our current opening roles</p>
                            </div>
                            <div className={`jumbotron ${styles.jumbotronStyles}`} id="jumbotron">

                                <div className="row">

                                    <div className="col-md-10 offset-md-1">

                                        <h4 className={styles.h4}>Current Openings </h4>
                                        <Accordion className={styles.accordion}>
                                            <Card>
                                                <Card.Header>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="0"
                                                                className={styles.btnLink} onClick={() => { this.handleClick(0) }}>
                                                                <p className="posts">1. Technical Writer &nbsp; <span className={unread1 ? 'hidden' : ''} >{plusIcon}</span><span className={unread1 ? '' : 'hidden'} >{minusIcon}</span></p>
                                                            </Accordion.Toggle>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <p className={styles.openPositions}>Open Positions: 1</p>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey="0">
                                                    <Card.Body className={styles.cardBody}>
                                                        <div>
                                                            <strong>Requirements:</strong>
                                                            <ul>
                                                                <li> Excellent grasp over English (written and verbal
                                                                skills)</li>
                                                                <li> Ability to think and write creatively</li>
                                                                <li>Created and executed storyboards. Proven experience of
                                                                technical editing/writing in a similar role.</li>
                                                            </ul>
                                                            <strong>Experience:</strong> <span>At least 2 years in content
                                                            development / writing</span> <br /><br />
                                                            <strong>Other requirements:</strong> Ability to work as
                                                            Technical Content writer steps to teach software applications
                                                        will be an added advantage.<br />

                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="1"
                                                                className={styles.btnLink} onClick={() => { this.handleClick(1) }}>
                                                                <p className="posts">2. Business Analyst &nbsp; <span className={unread2 ? 'hidden' : ''} >{plusIcon}</span><span className={unread2 ? '' : 'hidden'} >{minusIcon}</span></p>
                                                            </Accordion.Toggle>
                                                        </div>
                                                        <div className="col-lg-8">                                                            <p className={styles.openPositions}>Open Positions: 5</p>
                                                        </div>
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey="1">
                                                    <Card.Body className={styles.cardBody}>
                                                        <div>
                                                            <strong>Responsibilities:</strong>
                                                            <ul>
                                                                <li> Interaction with clients to understand their
                                                                requirements. </li>
                                                                <li>Documenting of requirements for future reference and
                                                                approvals.</li>
                                                                <li>Implementing and testing the requirements using
                                                                Sheetkraft.</li>
                                                                <li>Making changes to existing implementations based on
                                                                change requests.</li>
                                                                <li>Preparation of presentation material and reports</li>
                                                                <li>Interaction with clients to understand their
                                                                requirements and implement them.</li>
                                                            </ul>

                                                            <strong>Requirements:</strong>
                                                            <ul>
                                                                <li>Excellent logical, analytical and mathematical skills.
                                                            </li>
                                                                <li>Excellent verbal and written communication skills.</li>
                                                                <li>B.E/B.Sc./B.Com/BCA (preference to IT) degree.</li>
                                                                <li>Degree of education B.E/BSc/B.com (preference to IT).
                                                            </li>
                                                                <li>Desirable amount of previous experience in similar work.
                                                            </li>
                                                                <li>0-1 years experience with Microsoft Excel will be
                                                                helpful.</li>
                                                                <li>Specific Skills required Microsoft Excel.</li>
                                                                <li>Knowledge in following: VBA Macros, Business process
                                                                automation.</li>

                                                            </ul>
                                                            <strong>Experience:</strong> <span>0-1 years of
                                                            experience</span>


                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="2"
                                                                className={styles.btnLink} onClick={() => { this.handleClick(2) }}>
                                                                <p className="posts">3. Programmer Analyst &nbsp; <span className={unread3 ? 'hidden' : ''} >{plusIcon}</span><span className={unread3 ? '' : 'hidden'} >{minusIcon}</span></p>
                                                            </Accordion.Toggle>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <p className={styles.openPositions}>Open Positions: 3</p>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey="2">
                                                    <Card.Body className={styles.cardBody}>
                                                        <div>
                                                            <strong>Requirements:</strong>
                                                            <ul>
                                                                < li > Development and support of Business Process Automation framework </li>
                                                                < li > Development and support of a Corporate Treasury Management application </li>
                                                                <li> Development and support of a Wholesale Loan Management application</li>
                                                                <li>Full stack development - Front end, Back-End and Database technologies</li>
                                                                <li>Acquire business domain knowledge in financial services sector related to Banking, Wealth management, Insurance, Brokerage, Payment Gateways, etc.</li>
                                                                <li>Interaction with clients to understand their requirements and implement them</li>
                                                            </ul>
                                                            <strong>Qualifications:</strong><span> BE/B.Tech/M.Tech/MS [Only from Premier Institutes - IIT/BITS/NIT/VIT/IIIT)</span>  <br /><br />
                                                            <strong>Experience:</strong> <span>0-2 years</span> <br /><br />
                                                            <strong>General Information related to the job:</strong>
                                                            <ul>
                                                                <li>
                                                                    <strong>Training requirements </strong>
                                                                    <p>Training on the software products and source code will be provided by the company.</p>
                                                                </li>
                                                                <li>
                                                                    <strong>Tools used</strong>
                                                                    <p>Visual Studio, Git, Excel</p>
                                                                </li>
                                                                <li>
                                                                    <strong>Specific Skills required </strong>
                                                                    <p>C++, C# and .NET Framework (WPF, WCF, and ASP.NET), HTML, JavaScript (React, Typescript), CSS.</p>
                                                                </li>
                                                                <li>
                                                                    <strong>Knowledge in following</strong>
                                                                    <p>Algorithms and Data Structures, Probability and Statistics, Basic concepts in Finance.</p>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                            <Card>
                                                <Card.Header>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="3"
                                                                className={styles.btnLink} onClick={() => { this.handleClick(3) }}>
                                                                <p className="posts">4. Sales and Marketing Executive&nbsp; <span className={unread4 ? 'hidden' : ''} >{plusIcon}</span><span className={unread4 ? '' : 'hidden'} >{minusIcon}</span></p>
                                                            </Accordion.Toggle>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <p className={styles.openPositions}>Open Positions: 1</p>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body className={styles.cardBody}>
                                                        <div>
                                                            <strong>Job overview:</strong>
                                                            <ul>
                                                                <li> In house sales and marketing assistant responsible for creating various content like PPTs, Social Media content for various initiatives, and other requirements of the Business.</li>
                                                            </ul>
                                                            <strong>Responsibilities:</strong>
                                                            <ul>
                                                                <li> Assist the Sales and Marketing Heads in conducting market research, identifying selling opportunities and evaluating customer needs. </li>
                                                                <li> Creating content through PowerPoint presentations, case studies, sales pitches to onboard new clients. </li>
                                                                <li> Setting up meetings with potential clients and following up with them. </li>
                                                                <li>Helping the heads create sales and marketing strategies and various campaigns for the company. </li>
                                                                <li>Producing creative sales and marketing content.</li>
                                                            </ul>
                                                            <strong>Qualification:</strong><span> Any graduate preferably BMS/MMS</span> <br /><br />
                                                            <strong>Experience:</strong><span> 1-2 years. Prior exposure to sales and marketing field would be beneficial</span> <br /><br />
                                                            <strong>Requirements:</strong>
                                                            <ul>
                                                                <li>
                                                                    Need someone who had done enterprise sales.
                                                                </li>
                                                                <li>
                                                                    More focus on b2b sales is required.
                                                                </li>
                                                                <li>
                                                                    Proven experience as a Sales or marketing Executive or relevant experience in college or professional career
                                                                </li>
                                                                <li>
                                                                    Verbal and written Proficiency in English is a must.
                                                                </li>
                                                                <li>
                                                                    Knowledge of MS Office(PowerPoint, Excel, Word) and Advanced Excel would be an advantage.
                                                                </li>
                                                                <li>
                                                                    Should be able to learn to use marketing software/ tools
                                                                </li>
                                                                <li>
                                                                    Should be passionate about technology
                                                                </li>
                                                                <li>
                                                                    Strong networking and management skills.
                                                                </li>
                                                                <li>
                                                                    Fast learner and driven to take initiatives
                                                                </li>

                                                            </ul>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>

                                            <Card>
                                                <Card.Header>
                                                    <div className="row">
                                                        <div className="col-lg-4">
                                                            <Accordion.Toggle as={Button} variant="link" eventKey="4"
                                                                className={styles.btnLink} onClick={() => { this.handleClick(4) }}>
                                                                <p className="posts">5. Senior Sales Manager&nbsp; <span className={unread5 ? 'hidden' : ''} >{plusIcon}</span><span className={unread5 ? '' : 'hidden'} >{minusIcon}</span></p>
                                                            </Accordion.Toggle>
                                                        </div>
                                                        <div className="col-lg-8">
                                                            <p className={styles.openPositions}>Open Positions: 1</p>
                                                        </div>
                                                    </div>

                                                </Card.Header>
                                                <Accordion.Collapse eventKey="4">
                                                    <Card.Body className={styles.cardBody}>
                                                        <div>
                                                            {/* <strong>Job overview:</strong>
                                                            <ul>
                                                                <li> In house sales and marketing assistant responsible for creating various content like PPTs, Social Media content for various initiatives, and other requirements of the Business.</li>
                                                            </ul> */}
                                                            <strong>Responsibilities:</strong>
                                                            <ul>
                                                                <li> A proven track record of meeting or exceeding sales goals. He/ she must have good connects and references list and must hit the ground running as soon as he joins. He must show results as soon as he joins.</li>
                                                                <li> Strong experience selling enterprise software solutions or B2B sales of software products (RPA or automation product sales is a big plus)</li>
                                                                <li> Good knowledge of enterprise software architecture and technologies and business process mgmt. an advantage</li>
                                                                <li> Work closely with pre-sales team for getting the POC executed at prospects.</li>
                                                                <li> Possess the intelligence and aptitude to position the benefits of our Automation solutions. Adequate training will be provided for the same.</li>
                                                                <li> Advanced knowledge of consultative selling including prospecting, qualifying, presenting, trial closing, objection handling and closing</li>
                                                                <li> Organized and self-motivated while working independently and in the team to set and achieve goals</li>
                                                                <li> High energy with the ability to excel in an entrepreneurial, fast-changing environment</li>
                                                                <li> Solid computer knowledge including proficiency with software applications</li>
                                                                <li> Experience in the BFSI domain is a plus.</li>
                                                                <li> Demonstrable technical depth with the ability to effectively communicate with both technical and non-technical stakeholders</li>
                                                                <li> Drive sales by building partnerships channels and strategic alliances</li>

                                                            </ul>
                                                            <strong>Qualification:</strong><span> Btech, BSC IT</span> <br /><br />
                                                            <strong>Experience:</strong><span> 5+ full sales cycle experience in B2B software field.</span> <br /><br />

                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>

                                            </Card>
                                        </Accordion>
                                    </div>
                                </div>
                                <br /><br />
                                <div className={styles.emailId}>
                                    <p className={styles.link}><em>If interested please send your updated resumes to
                                        <b>&nbsp;iona@quantumphinance.com</b></em></p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                < SheetkraftFooter />
            </div>
        )
    }
}